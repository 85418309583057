import React from "react"
import Wrapper from "../../utils/wrapper"
import { StaticImage } from "gatsby-plugin-image"

// TODO: Logos should come from a CMS or Cloudinary
const HomeClients = () => {
  const logoStyle = "filter-grayscale "
  return (
    <Wrapper
      rightFull={true}
      centerContent={
        <>
          <h2 className="font-light opacity-30">You are in good company</h2>

          <section className="grid md:grid-cols-4 grid-cols-3">
            <StaticImage
              src="../../../images/logos_icons/clients/nomba.png"
              placeholder="blurred"
              alt="Nomba"
              className={logoStyle + " order-1 "}
            />
            {/* <StaticImage
              src="../../../images/logos_icons/clients/ondo-state.png"
              placeholder="blurred"
              alt="Ondo state"
              className={logoStyle + " order-1 "}
            /> */}

            <StaticImage
              src="../../../images/logos_icons/clients/thrive-agric.png"
              placeholder="blurred"
              alt="Thrive Agric"
              className={logoStyle + " order-2"}
            />

            <StaticImage
              src="../../../images/logos_icons/clients/okra.png"
              placeholder="blurred"
              alt="Okra"
              className={logoStyle + "order-3 "}
            />

            <StaticImage
              src="../../../images/logos_icons/clients/afriex.png"
              placeholder="blurred"
              alt="Afriex"
              className={logoStyle + "md:order-4 order-6"}
            />

            <StaticImage
              src="../../../images/logos_icons/clients/piggyvest.png"
              placeholder="blurred"
              alt="Piggyvest"
              className={logoStyle + "md:order-5 order-4"}
            />

            <StaticImage
              src="../../../images/logos_icons/clients/volition-capital.png"
              placeholder="blurred"
              alt="Volition Capital"
              className={logoStyle + "md:order-6 order-5"}
            />

            <StaticImage
              src="../../../images/logos_icons/clients/titan-trust-bank.png"
              placeholder="blurred"
              alt="Titan Trust Bank"
              className={logoStyle + "md:order-7 order-8"}
            />

            <StaticImage
              src="../../../images/logos_icons/clients/statecraft.png"
              placeholder="blurred"
              alt="State Craft"
              className={logoStyle + "md:order-8 order-9"}
            />

            <StaticImage
              src="../../../images/logos_icons/clients/accelerex.png"
              placeholder="blurred"
              alt="Accelerex"
              className={logoStyle + "md:order-9 order-7"}
            />

            <StaticImage
              src="../../../images/logos_icons/clients/ondo-state.png"
              placeholder="blurred"
              alt="Thrive Agric"
              className={logoStyle + "md:order-10 order-11"}
            />

            <StaticImage
              src="../../../images/logos_icons/clients/lagos-business-school.png"
              placeholder="blurred"
              alt="Lagos Business School"
              className={logoStyle + "md:order-11 order-10"}
            />

            <StaticImage
              src="../../../images/logos_icons/clients/tech4dev.png"
              placeholder="blurred"
              alt="Tech4Dev"
              className={logoStyle + "order-12"}
            />
          </section>
        </>
      }
    />
  )
}

export default HomeClients
