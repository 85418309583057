import React from 'react'
import BodyTitleContent from '../../utils/title-content'
import HomeBlogPreview from './preview'
import { graphql, useStaticQuery } from "gatsby"

const HomeBlog = () => {

const recentThoughts = useStaticQuery(graphql`{
    allWpPost(limit: 3, sort: {fields: date, order: DESC}) {
        nodes {
      title
      slug
    }
  }
}`)

const thoughtList = recentThoughts.allWpPost.nodes.map(thought => (
    <HomeBlogPreview 
    key={thought.title}
    url={`/${thought.slug}`} 
    title={thought.title}
    />
    ))
    return (
        <BodyTitleContent 
            heading="From our blog"
            content={
                <ul className="list-none list-outside">
                    {thoughtList}
                </ul>
            }
        />
    )
}

export default HomeBlog