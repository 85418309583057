import React from 'react'
import {Link} from 'gatsby'

const HomeBlogPreview = ({title, url}) => {
    return (
        <Link to={url}>
            <li className="border-b pb-2 mb-4 border-black hover:opacity-30">{title}</li>
        </Link>
    )
}

export default HomeBlogPreview