import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import WorkPreview from "../../utils/work-preview"
import {showWorkInThisEnvironment} from "../../../helpers/works"

const HomeWorks = ({ start = true }) => {
  const allWorks = useStaticQuery(
    graphql`
      {
        allWpWork(sort: { order: DESC, fields: date }, limit: 6) {
          nodes {
            title
            content
            slug
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                }
              }
            }

            workFields {
              clientprojectName
              introHeading
              inDevelopment
            }
          }
        }
      }
    `
  )


  const startresults = allWorks.allWpWork.nodes.filter((work, index) => index < 3 && showWorkInThisEnvironment(work) )
  // const homeworkstart = "Start Results"
  const homeworkstart = startresults.map((work, i) => (
    <WorkPreview key={i} work={work} />
  ))
  // const homeworkend = "End Results"
  const homeworkend = allWorks.allWpWork.nodes
    .filter((work, index) => index >= 3 && showWorkInThisEnvironment(work))
    .map((work, i) => <WorkPreview key={i} work={work} />)

  return (
    <section>
      {start ? (
        homeworkstart
      ) : (
        <>
          <section></section>
          {homeworkend}
        </>
      )}
    </section>
  )
}

export default HomeWorks
