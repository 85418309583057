import React from "react"
import loadable from "@loadable/component"
import PageHeader from "../components/utils/page-header"

import Magic from "../components/utils/magic"
import BodyTitleContent from "../components/utils/title-content"
import HomeBlog from "../components/home/blog"
import HomeClients from "../components/home/clients"
import HomeWorks from "../components/home/works"
import { ReachOutButton } from "../components/utils"

const Layout = loadable(() => import("../components/layout"))

const IndexPage = () => {
  const seo = {
    title: "FourthCanvas - Brand Centric Design Agency",
    ignoreSiteName: true,
  }

  return (
    <Layout seo={seo} fallback={<p>Loading...</p>}>
      <PageHeader
        home={true}
        text={
          <>
            Where businesses
            <br />
            become brands
          </>
        }
      />

      <HomeWorks />

      <BodyTitleContent
        content={
          <>
            <h2 className="font-light">For the few</h2>
            <p>
              We do incredible work for the most incredible people — who improve
              the quality of the human experience. Across brand strategy,
              design, implementation, and management, we go all the way for
              visionary business leaders who make the work we do worth it.
            </p>

            <ReachOutButton />
          </>
        }
      />

      <HomeWorks start={false} />
      <BodyTitleContent
        heading="Working with us"
        content={
          <p>
            Not every brand can be compelling. It is hard work and only a very
            small percentage of the world’s brands actually get it right. <br />
            <br />
            To work with us, we require thoroughness and commitment to the
            process and a strong belief in the transformative power of branding.
            Why? A brand can only be as strong as the systems and people behind
            it.
          </p>
        }
      />

      <HomeClients />
      <Magic />
      <HomeBlog />
    </Layout>
  )
}

export default IndexPage
