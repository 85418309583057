import React from "react"

import { useContactFormContext } from "../../contexts/contact-form-context"
import ViewProjectArrow from "../icons/view-project-arrow"

const ReachOutButton = () => {
  const { openContactForm } = useContactFormContext()
  return (
    <button onClick={openContactForm} className="flex items-center magic">
      <p className="sm:text-2xl mb-0 text-xl font-normal pr-2">Reach out</p>
      <ViewProjectArrow />
    </button>
  )
}

export default ReachOutButton
